import { useState, useRef } from "react";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/girls.jpg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import emailjs from "@emailjs/browser";
import { Route, Link } from "react-router-dom";

export const Contact = () => {
  const formInitialDetails = {
    name: "",
    last_name: "",
    email: "",
    cellphone: "",
    comments: "",
    check: "",
    esUsuaria: "",
    esConductora: "",
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Enviar");
  const [status, setStatus] = useState({});

  const [isChecked, setIsChecked] = useState(false);

  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }

  const handleCheckboxData = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setFormDetails({ ...formDetails, check: e.target.value });
    } else {
      setFormDetails({ ...formDetails, check: "" });
    }
  };

  function handleLabelClick(event) {
    event.preventDefault();
    window.open("https://bit.ly/3LHPwDq", "_blank");
  }

  const [isCheckedType, setIsCheckedType] = useState(false);
  const [isCheckedTypeUser, setIsCheckedTypeUser] = useState(false);

  const handleCheckboxTypeDriver = (e) => {
    setIsCheckedType(e.target.checked);
    if (e.target.checked) {
      setIsCheckedTypeUser(false);
      setIsCheckedType(true);
      setFormDetails({ ...formDetails, esConductora: e.target.value });
    } else {
      setFormDetails({ ...formDetails, esConductora: "" });
    }
  };

  const handleLabelTypeDriver = () => {
    setIsCheckedTypeUser(!isCheckedTypeUser);
  };

  const handleCheckboxTypeUser = (e) => {
    setIsCheckedTypeUser(e.target.checked);
    if (e.target.checked) {
      setIsCheckedTypeUser(true);
      setIsCheckedType(false);
      setFormDetails({ ...formDetails, esUsuaria: e.target.value });
    } else {
      setFormDetails({ ...formDetails, esUsuaria: "" });
    }
  };

  const handleLabelTypeUser = () => {
    setIsCheckedTypeUser(!isCheckedTypeUser);
    setIsCheckedType(false);
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText("Enviando...");
    emailjs
      .sendForm(
        "service_72q6mg9",
        "template_1y375kp",
        form.current,
        "QDrXR9bWrO5qjFwuV"
      )
      .then(
        (result) => {
          setButtonText("Enviado");
          console.log(result.text);
          setStatus({ succes: true, message: "Mensaje Enviado con éxito" });
          setFormDetails(formInitialDetails);
        },
        (error) => {
          console.log(error.text);
          setStatus({
            succes: false,
            message:
              "Algo salió mal, vuelve a intentarlo o verifica que los campos esten diligenciados correctamente.",
          });
        }
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");

    if (isCheckedTypeUser && !isCheckedType) {
      formDetails.esConductora = ""; // Vaciar la opción "Es Conductora" si estaba seleccionada
    } else if (isCheckedType && !isCheckedTypeUser) {
      formDetails.esUsuaria = ""; // Vaciar la opción "Es Usuaria" si estaba seleccionada
    }

    let response = await fetch(
      "https://4jfoopp18h.execute-api.us-east-1.amazonaws.com/LuguuLeads",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },

        body: JSON.stringify(formDetails),
      }
    );
    setButtonText("Send");

    await sendEmail(e);
    setFormDetails(formInitialDetails);
    if (response.status === 200) {
      setStatus({ succes: true, message: "Mensaje Enviado con éxito" });
      setFormDetails(formInitialDetails);
    } else {
      setStatus({
        succes: false,
        message:
          "Algo salió mal, vuelve a intentarlo o verifica que los campos esten diligenciados correctamente.",
      });
    }
  };

  const h2Styles = {
    color: "#93278F",
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2 style={h2Styles}>Una comunidad unida, Comencemos</h2>
                  <p style={h2Styles}>
                    Contactanos y vive una nueva experiencia de movilidad,
                    registra tus datos y nos pondremos en contacto contigo
                  </p>
                  <form ref={form} onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <input
                          type="checkbox"
                          onChange={handleCheckboxTypeUser}
                          checked={isCheckedTypeUser}
                          value="Es Usuaria"
                          name="esUsuaria"
                        />
                        <label style={h2Styles} onClick={handleLabelTypeUser}>
                          Eres Usuaria
                        </label>
                      </Col>
                      <Col>
                        <input
                          type="checkbox"
                          onChange={handleCheckboxTypeDriver}
                          checked={isCheckedType}
                          value="Es Conductora"
                          name="esConductota"
                        />
                        <label style={h2Styles} onClick={handleLabelTypeDriver}>
                          Eres Conductora
                        </label>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          required
                          value={formDetails.name}
                          name="user_name"
                          placeholder="Nombres *"
                          onChange={(e) => onFormUpdate("name", e.target.value)}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          required
                          value={formDetails.last_name}
                          name="last_name"
                          placeholder="Apellidos *"
                          onChange={(e) =>
                            onFormUpdate("last_name", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="email"
                          required
                          value={formDetails.email}
                          name="user_email"
                          placeholder="Correo *"
                          onChange={(e) =>
                            onFormUpdate("email", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="number"
                          value={formDetails.cellphone}
                          name="cellphone"
                          placeholder="Celular (Opcional)"
                          onChange={(e) =>
                            onFormUpdate("cellphone", e.target.value)
                          }
                          handlePhoneNumberChange
                        />
                      </Col>

                      <Col size={8} className="px-1">
                        <textarea
                          rows="3"
                          value={formDetails.comments}
                          name="comments"
                          placeholder="Comentarios"
                          onChange={(e) =>
                            onFormUpdate("comments", e.target.value)
                          }
                        ></textarea>
                      </Col>
                      <Row>
                        <Link to="/politicas" target="_blank">
                          Política de Tratamiento de datos
                        </Link>
                      </Row>

                      <Row>
                        <center>
                          <button type="submit" value="Send">
                            <span>{buttonText}</span>
                          </button>
                        </center>
                      </Row>
                    </Row>

                    {status.message && (
                      <Col>
                        <p
                          className={
                            status.success === false ? "danger" : "success"
                          }
                        >
                          {status.message}
                        </p>
                      </Col>
                    )}
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

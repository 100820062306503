import React from "react";
import { Container } from "react-bootstrap";

export const Terminos = () => {
  return (
    <div
      style={{ marginTop: "220px", marginLeft: "30px", marginRight: "30px" }}
    >
      <h1 style={{ color: "black" }}>
        POLÍTICAS DE PRIVACIDAD LUGUU
      </h1>
      <p>
        Esta página tiene como objetivo informar a las usuarias sobre nuestras
        políticas en relación con la recopilación, uso y divulgación de
        Información Personal al utilizar la aplicación LUGUU Al optar por
        utilizar nuestro Servicio, usted acepta la recopilación y uso de
        información de acuerdo con esta política. La información personal que
        recopilamos se utiliza para proporcionar y mejorar el Servicio. No
        compartiremos su información, salvo según se describe en esta Política
        de Privacidad. Los términos utilizados en esta Política de Privacidad
        tienen el mismo significado que en nuestros Términos y Condiciones, a
        los que se puede acceder a través de la aplicación, a menos que se
        defina lo contrario en esta Política de Privacidad.
      </p>
      <h2>INTRODUCCION</h2>
      <p>
        LUGUU, consciente de la importancia de preservar
        los datos otorgados por sus clientes, ha elaborado la presente política,
        atendiendo para ello lo establecido en los artículos 15 y 20 de la
        Constitución Política de Colombia, así como las disposiciones
        establecidas en la Ley 1581 de 2012 y su posterior Decreto Reglamentario
        1377 de 2013. En virtud de lo anterior, se ha determinado que la
        política de tratamiento y protección de datos personales establecida en
        el presente documento, le es aplicable a LUGUU, y
        a las demás entidades, personas naturales o jurídicas y/o terceros con
        los que LUGUU, celebre contratos para el
        almacenamiento, procesamiento, transferencia, recolección, uso,
        transmisión, actualización y gestión de los datos otorgados por los
        clientes.
      </p>
      <h2>DEFINICIONES</h2>
      <p>
        Para el correcto entendimiento de la presente política, se definen
        conforme a lo determinado en la ley 1581 de 2012 y su decreto
        reglamentario 1377, las siguientes definiciones: Autorización:
        Consentimiento previo, expreso e informado del Titular para llevar a
        cabo el Tratamiento de datos personales. Aviso de privacidad: Es la
        notificación realizada por el Responsable en los casos en los que no sea
        posible poner a disposición del Titular las políticas de tratamiento de
        la información, en la cual el Responsable le informe al Titular sobre el
        tratamiento de sus datos personales, comunicándole acerca de la
        existencia de las políticas de tratamiento de información que le serán
        aplicables, el cómo podrá acceder a las mismas y la finalidad del
        tratamiento que pretenda dar a los datos personales. Base de Datos:
        Conjunto organizado de datos personales que sea objeto de Tratamiento.
        Dato personal: Cualquier información vinculada o que pueda asociarse a
        una o varias personas naturales determinadas o determina Datos
        sensibles: Se entiende por datos sensibles aquellos que afectan la
        intimidad del Titular o cuyo uso indebido puede generar su
        discriminación, tales como aquellos que revelen el origen racial o
        étnico, la orientación política, las convicciones religiosas o
        filosóficas, la pertenencia a sindicatos, organizaciones sociales, de
        derechos humanos o que promueva intereses de cualquier partido político
        o que garanticen los derechos y garantías de partidos políticos de
        oposición, así como los datos relativos a la salud, a la vida sexual, y
        los datos biométricos. Encargado del Tratamiento: Persona natural o
        jurídica, pública o privada, que por sí misma o en asocio con otros,
        realice el Tratamiento de datos personales por cuenta del Responsable
        del Tratamiento; Responsable del Tratamiento: Persona natural o
        jurídica, pública o privada, que por sí misma o en asocio con otros,
        decida sobre la base de datos y/o el Tratamiento de los datos; Titular:
        Persona natural cuyos datos personales sean objeto de Tratamiento.
        Tratamiento: Cualquier operación o conjunto de operaciones sobre datos
        personales, tales como la recolección, almacenamiento, uso, circulación
        o supresión. Transferencia: La transferencia de datos tiene lugar cuando
        el Responsable y/o Encargado del Tratamiento de datos personales,
        ubicado en Colombia, envía la información o los datos personales a un
        receptor, que a su vez es Responsable del Tratamiento y se encuentra
        dentro o fuera del país. Transmisión: Tratamiento de datos personales
        que implica la comunicación de los mismos dentro o fuera del territorio
        de la República de Colombia cuando tenga por objeto la realización de un
        Tratamiento por el Encargado por cuenta del Responsable.
      </p>
      <h2>
        OBJETIVOS DE LAS POLITICAS DE TRATAMIENTO Y PROTECCION DE DATOS
        PERSONALES
      </h2>
      <p>
        En este manual que contiene las políticas de tratamiento de datos
        personales, clientes encontrarán los lineamientos legales y
        empresariales bajo los cuales LUGUU, realiza el
        tratamiento de sus datos e información personal, las finalidades, sus
        derechos como clientes, así como los procedimientos establecidos para el
        ejercicio de tales derechos ante LUGUU
      </p>

      <h2>AMBITO DE APLICACIÓN</h2>
      <p>
        La política de tratamiento de datos establecida en el presente manual,
        aplica a los datos personales, información y archivos registrados en las
        bases de datos de clientes de LUGUU, que sean
        susceptibles de tratamiento en virtud de la autorización otorgada para
        el efecto, con el fin de tratar dichos datos conforme lo establece la
        ley y la autorización otorgada. En igual sentido esta política se hace
        extensiva a los terceros que en territorio Colombiano hagan tratamiento
        de los datos personales de los clientes de LUGUU,
        en virtud de la celebración de contratos para el almacenamiento,
        procesamiento, transferencia, recolección, uso, transmisión,
        actualización y gestión de los datos otorgados por los clientes
      </p>

      <h2>PRINCIPIOS</h2>

      <p>
        La Ley 1581 de 2012, ha determinado que el tratamiento de datos debe
        ejecutarse bajo los principios de:
      </p>

      <h3>PRINCIPIO DE LEGALIDAD</h3>

      <p>
        El Tratamiento a que se refiere la presente ley es una actividad reglada
        que debe sujetarse a lo establecido en ella y en las demás disposiciones
        que la desarrollen.
      </p>

      <h2>1. PRINCIPIO DE LEGALIDAD:</h2>
      <p>
        El Tratamiento a que se refiere la presente ley es una actividad reglada
        que debe sujetarse a lo establecido en ella y en las demás disposiciones
        que la desarrollen.
      </p>
      <h2>2. PRINCIPIO DE FINALIDAD:</h2>
      <p>
        El Tratamiento debe obedecer a una finalidad legítima de acuerdo con la
        Constitución y la Ley, la cual debe ser informada al Titular.
      </p>
      <h2>3. PRINCIPIO DE LIBERTAD:</h2>
      <p>
        El Tratamiento sólo puede ejercerse con el consentimiento, previo,
        expreso e informado del Titular. Los datos personales no podrán ser
        obtenidos o divulgados sin previa autorización, o en ausencia de mandato
        legal o judicial que releve el consentimiento.
      </p>
      <h2>4. PRINCIPIO DE VERACIDAD O CALIDAD:</h2>
      <p>
        La información sujeta a Tratamiento debe ser veraz, completa, exacta,
        actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de
        datos parciales, incompletos, fraccionados o que induzcan a error.
      </p>
      <h2>5. PRINCIPIO DE TRANSPARENCIA:</h2>
      <p>
        En el Tratamiento debe garantizarse el derecho del Titular a obtener del
        Responsable del Tratamiento o del Encargado del Tratamiento, en
        cualquier momento y sin restricciones, información acerca de la
        existencia de datos que le conciernan.
      </p>
      <h2>6. PRINCIPIO DE ACCESO Y CIRCULACIÓN RESTRINGIDA:</h2>
      <p>
        El Tratamiento se sujeta a los límites que se derivan de la naturaleza
        de los datos personales, de las disposiciones de la presente ley y la
        Constitución. En este sentido, el Tratamiento sólo podrá hacerse por
        personas autorizadas por el Titular y/o por las personas previstas en la
        presente ley; Los datos personales, salvo la información pública, no
        podrán estar disponibles en Internet u otros medios de divulgación o
        comunicación masiva, salvo que el acceso sea técnicamente controlable
        para brindar un conocimiento restringido sólo a los Titulares o terceros
        autorizados conforme a la presente ley.
      </p>
      <h2>7. PRINCIPIO DE SEGURIDAD:</h2>
      <p>
        La información sujeta a Tratamiento por el Responsable del Tratamiento o
        Encargado del Tratamiento a que se refiere la presente ley, se deberá
        manejar con las medidas técnicas, humanas y administrativas que sean
        necesarias para otorgar seguridad a los registros evitando su
        adulteración, pérdida, consulta, uso o acceso no autorizado o
        fraudulento.
      </p>
      <h2>8. PRINCIPIO DE CONFIDENCIALIDAD:</h2>
      <p>
        Todas las personas que intervengan en el Tratamiento de datos personales
        que no tengan la naturaleza de públicos están obligadas a garantizar la
        reserva de la información, inclusive después de finalizada su relación
        con alguna de las labores que comprende el Tratamiento, pudiendo sólo
        realizar suministro o comunicación de datos personales cuando ello
        corresponda al desarrollo de las actividades autorizadas en la presente
        ley y en los términos de la misma.
      </p>
   
      <h2>4. ELIMINACIÓN DE CUENTA:</h2>
      <h3>Conductora:</h3>
      <li>Solicitud de Eliminación de Cuenta:</li>
      <li>
        Las conductoras pueden solicitar la eliminación de su cuenta a través de
        la sección correspondiente en la aplicación. Este proceso será
        gestionado de manera eficiente y respetando los plazos legales que
        abarcan los 5 días hábiles.
      </li>
      <li>Proceso de Estudio de Paz y Salvo:</li>
      <li>
        Antes de la eliminación de la cuenta, se llevará a cabo un proceso de
        estudio para garantizar que la conductora esté al día en sus
        transacciones y obligaciones con nosotros, asegurando un cierre de
        cuenta sin deudas pendientes o procesos ante la ley que puedan requerir
        documentación o datos personales.
      </li>
      <li>Eliminación de Datos y Documentos:</li>
      <li>
        Una vez completado el proceso de eliminación de cuenta, todos los datos
        personales y documentos asociados serán eliminados de nuestros bancos de
        datos, cumpliendo con las normativas de privacidad y protección de
        datos.
      </li>
      <li>Comunicación para Solicitud de Eliminación:</li>
      <li>
        En caso de no poder realizar la solicitud de eliminación de cuenta a
        través de la aplicación, las conductoras pueden comunicarse con nosotros
        mediante la página principal, correo electrónico o WhatsApp corporativo
        para gestionar la eliminación de su cuenta.
      </li>
      <h3>Pasajera:</h3>
      <li>Solicitud de Eliminación de Cuenta:</li>
      <li>
        Las pasajeras pueden solicitar la eliminación de su cuenta a través de
        la sección correspondiente en la aplicación. Este proceso será
        gestionado de manera eficiente y respetando los plazos legales que
        abarcan los 5 días legales.
      </li>
      <li>Proceso de Estudio:</li>
      <li>
        Previo a la eliminación de la cuenta, se llevará a cabo un proceso de
        estudio para garantizar que no existan transacciones pendientes o
        situaciones que requieran atención especial.
      </li>
      <li>Comunicación para Solicitud de Eliminación:</li>
      <li>
        En caso de no poder realizar la solicitud de eliminación de cuenta a
        través de la aplicación, las pasajeras pueden comunicarse con nosotros
        mediante la página principal, correo electrónico o WhatsApp corporativo
        para gestionar la eliminación de su cuenta.
      </li>
      <li>
        Estas políticas buscan asegurar un manejo transparente y seguro de los
        datos, así como proporcionar a nuestras usuarias y conductores diversas
        opciones para gestionar la eliminación de sus cuentas de manera
        efectiva.
      </li>
      <h1>DERECHOS DE LOS TITULARES</h1>
      <h2>1. PERSONAS FACULTADAS PARA EJERCER LOS DERECHOS:</h2>
      <p>
        Los derechos de los Titulares de la información podrán ejercerse por las
        siguientes personas:
      </p>
      <li>
        Por el Titular, quien deberá acreditar su identidad en forma suficiente
        por los distintos medios que le ponga a disposición el responsable.
      </li>
      <li>Por sus causahabientes, quienes deberán acreditar tal calidad.</li>
      <li>
        Por el representante y/o apoderado del Titular, previa acreditación de
        la representación o apoderamiento. •
      </li>
      <li>
        Por el representante y/o apoderado del Titular, previa acreditación de
        la representación o apoderamiento.
      </li>
      <li>Por estipulación a favor de otro o para otro.</li>
      <h2>2. DERECHOS QUE PUEDE EJERCER EL TITULAR DE LA INFORMACION:</h2>
      <p>
        Los derechos de que goza el titular de la información, al momento de
        suministrar sus datos a LUGUU, de conformidad con
        lo estipulado en la ley, son:
      </p>
      <li>
        Derecho a conocer: El titular de la información, podrá en cualquier
        tiempo y de forma gratuita, solicitar a LUGUU,
        información acerca de cuáles de sus datos personales reposan dentro de
        las bases de datos de la compañía y cuál es el uso que se le da a la
        misma, además conocer cuáles de los datos aportados, han sido objeto de
        transferencia.
      </li>
      <li>
        Derecho a actualizar: El titular de la información, podrá en cualquier
        tiempo y de forma gratuita, solicitar a LUGUU, que
        se actualicen sus datos personales, para lo cual deberá proporcionar en
        forma veraz, oportuna y completa la información que desea se incluya en
        la base de datos y/o archivo de la Compañía, lo cual comprende la
        entrega de la documentación soporte que se requiera para tal efecto.
      </li>
      <li>
        Derecho a rectificar: Si el titular de la información, considera que los
        datos que tiene LUGUU, dentro de su base de datos,
        son inexactos o incompletos podrá solicitarle en cualquier momento y en
        forma gratuita, que se corrija tal información, para lo cual deberá
        acudir de forma personal, identificándose en debida forma, a través de
        los canales habilitados por la empresa, explicando en qué consiste la
        inexactitud o inconsistencia y suministrar en forma correcta y completa
        la información que desea se incorpore en la base de datos, lo cual
        comprende la entrega de la documentación soporte que se requiera para
        tal efecto.
      </li>
      <li>
        Derecho a suprimir: En cualquier momento y sin costo alguno el titular
        de la información podrá solicitar a LUGUU, que
        elimine los datos que a bien considere necesario, para lo cual deberá
        identificarse en debida forma y señalar cuál es la información a
        eliminar, acompañando dicha solicitud de la documentación soporte que se
        requiera para tal efecto.
      </li>
      <li>
        Derecho a revocar su autorización: El titular de la información estará
        en la capacidad de revocar la autorización otorgada a INVERSIONES
        LUGUU, para el tratamiento de sus datos personales, siempre y
        cuando no tenga un deber legal o contractual en virtud del cual deba
        mantener vigente dicha autorización.
      </li>
      <li>
        Derecho a solicitar prueba de la autorización: El titular de la
        información podrá solicitar a LUGUU, acreditar la
        existencia de la autorización impartida en los casos en que por ley la
        misma era necesaria para el tratamiento de sus datos. •
      </li>
      <li>
        Derecho a presentar quejas ante la Superintendencia de Industria y
        Comercio: EL titular de la información estará facultado a presentar
        quejas y reclamos ante la Superintendencia de Industria y Comercio si
        considera que se ha presentado alguna infracción a las normas que
        regulan la protección de sus datos personales, El Titular o
        causahabiente sólo podrá elevar queja ante la Superintendencia de
        Industria y Comercio una vez haya agotado el trámite de consulta o
        reclamo ante el Responsable del Tratamiento o Encargado del Tratamiento.
      </li>
      <h2>3 PETICIONES QUEJAS O RECLAMOS</h2>
      <p>
        El Titular o sus causahabientes que consideren que la información
        contenida en una base de datos debe ser objeto de corrección,
        actualización o supresión, o cuando adviertan el presunto incumplimiento
        de cualquiera de los deberes contenidos en esta ley, podrán presentar un
        reclamo ante el Responsable del Tratamiento o el Encargado del
        Tratamiento el cual será tramitado bajo las siguientes reglas:
      </p>
      <ol>
        <li>
          El reclamo se formulará mediante solicitud dirigida al Responsable del
          Tratamiento o al Encargado del Tratamiento, con la identificación del
          Titular, la descripción de los hechos que dan lugar al reclamo, la
          dirección, y acompañando los documentos que se quiera hacer valer. Si
          el reclamo resulta incompleto, se requerirá al interesado dentro de
          los cinco (5) días siguientes a la recepción del reclamo para que
          subsane las fallas. Transcurridos dos (2) meses desde la fecha del
          requerimiento, sin que el solicitante presente la información
          requerida, se entenderá que ha desistido del reclamo. En caso de que
          quien reciba el reclamo no sea competente para resolverlo, dará
          traslado a quien corresponda en un término máximo de dos (2) días
          hábiles e informará de la situación al interesado.
        </li>
        <li>
          Una vez recibido el reclamo completo, se incluirá en la base de datos
          una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un
          término no mayor a dos (2) días hábiles. Dicha leyenda deberá
          mantenerse hasta que el reclamo sea decidido.
        </li>
        <li>
          El término máximo para atender el reclamo será de quince (15) días
          hábiles contados a partir del día siguiente a la fecha de su recibo.
          Cuando no fuere posible atender el reclamo dentro de dicho término, se
          informará al interesado los motivos de la demora y la fecha en que se
          atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8)
          días hábiles siguientes al vencimiento del primer término.
        </li>
      </ol>
      <p>
        El Titular o sus causahabientes que consideren que la información
        contenida en una base de datos debe ser objeto de corrección,
        actualización o supresión, o cuando adviertan el presunto incumplimiento
        de cualquiera de los deberes contenidos en esta ley, podrán presentar un
        reclamo ante el Responsable del Tratamiento o el Encargado del
        Tratamiento el cual será tramitado bajo las siguientes reglas:
      </p>
      <ol>
        <li>
          El reclamo se formulará mediante solicitud dirigida al Responsable del
          Tratamiento o al Encargado del Tratamiento, con la identificación del
          Titular, la descripción de los hechos que dan lugar al reclamo, la
          dirección, y acompañando los documentos que se quiera hacer valer. Si
          el reclamo resulta incompleto, se requerirá al interesado dentro de
          los cinco (5) días siguientes a la recepción del reclamo para que
          subsane las fallas. Transcurridos dos (2) meses desde la fecha del
          requerimiento, sin que el solicitante presente la información
          requerida, se entenderá que ha desistido del reclamo. En caso de que
          quien reciba el reclamo no sea competente para resolverlo, dará
          traslado a quien corresponda en un término máximo de dos (2) días
          hábiles e informará de la situación al interesado.
        </li>
        <li>
          Una vez recibido el reclamo completo, se incluirá en la base de datos
          una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un
          término no mayor a dos (2) días hábiles. Dicha leyenda deberá
          mantenerse hasta que el reclamo sea decidido.
        </li>
        <li>
          El término máximo para atender el reclamo será de quince (15) días
          hábiles contados a partir del día siguiente a la fecha de su recibo.
          Cuando no fuere posible atender el reclamo dentro de dicho término, se
          informará al interesado los motivos de la demora y la fecha en que se
          atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8)
          días hábiles siguientes al vencimiento del primer término.
        </li>
      </ol>
      <h2>
        4. CANALES HABILITADOS PARA RECEPCIONAR PETICIONES QUEJAS O RECLAMOS
      </h2>
      <p>
        LUGUU, como Responsable del Tratamiento de los
        Datos suministrados, con el fin de hacer efectivos los derechos de
        acceso por parte de los titulares de la información, ha designado los
        siguientes canales de comunicación:
      </p>
      <ol>
        <li>Correo Electrónico: info@LUGUUio</li>
        <li>Link: https://LUGUUio/luguu</li>
        <li>Dirección: Calle 76 de # 89-31</li>
        <li>Horario de Atención: 8:00 a.m. a 5:00 p.m. de lunes a viernes.</li>
        <li>Teléfono: 3122695787</li>
      </ol>
      <h1>MECANISMOS DE SEGURIDAD</h1>
      <p>
        LUGUU, dispondrá de todos los medios técnicos,
        humanos y/o administrativos necesarios, con el fin de evitar la pérdida,
        acceso, uso, consulta, alteración por terceras personas no autorizadas,
        de la información suministrada por el titular de la información,
        partiendo de la naturaleza del principio de seguridad desarrollado por
        la legislación vigente.
      </p>

      <h3>RECOPILACIÓN Y USO DE LA INFORMACIÓN</h3>

      <p>
        Con el fin de mejorar la experiencia del usuario, solicitamos cierta
        información de identificación personal, que puede incluir, entre otros:
        -Nombre -Apellido -Correo Electrónico -Número de Teléfono La información
        proporcionada será retenida y utilizada de acuerdo con esta Política de
        Privacidad.
      </p>

      <h3>RECOPILACIÓN DE DATOS PERSONALES</h3>
      <p>
        Al usar o registrarse en nuestra aplicación, recopilamos datos de
        ubicación para habilitar: -Búsqueda de ubicación del vehículo -Ruta de
        viaje y navegación -Seguimiento de vehículos en tiempo real -Datos de
        Registro En caso de errores en la aplicación, recopilamos datos e
        información (a través de terceros) llamados DATOS DE REGISTRO. Estos
        pueden incluir información como la dirección de Protocolo de Internet
        (IP), nombre del dispositivo, versión del sistema operativo,
        configuración de la aplicación, fecha y hora de uso, y otras
        estadísticas.
      </p>

      <h3>COOKIES</h3>
      <p>
        Luguu no utiliza cookies de manera explícita, pero la aplicación puede
        hacer uso de código de terceros y bibliotecas que emplean cookies para
        mejorar los servicios. Usted puede aceptar o rechazar estas cookies
        según su preferencia. -Proveedores de Servicio -Podemos emplear empresas
        e individuos de terceros para: -Facilitar el Servicio -Proveer el
        Servicio en nuestro nombre -Realizar servicios relacionados con el
        Servicio -Analizar el uso de nuestro Servicio Estos terceros pueden
        tener acceso a su Información Personal solo para cumplir con las tareas
        asignadas en nuestro nombre.
      </p>

      <h3>SEGURIDAD</h3>

      <p>
        Valoramos la confianza que deposita en nosotros al proporcionar su
        información personal. Implementamos medios comercialmente aceptables
        para protegerla. Sin embargo, no hay un método de transmisión por
        Internet o almacenamiento electrónico que sea 100% seguro, y no podemos
        garantizar su seguridad absoluta.
      </p>

      <h3>PRIVACIDAD DE LOS NIÑOS</h3>

      <p>
        Luguu no está dirigido a personas menores de 13 años y no recopilamos
        información de identificación personal de niñas menores de 13 años. Si
        descubrimos que una niña nos ha proporcionado información personal, la
        eliminamos de inmediato de nuestros servidores.
      </p>

      <h3>ELIMINAR DATOS</h3>
      <p>
        Las usuarias pueden eliminar todos sus datos del sistema eliminando la
        cuenta desde la página de perfil en la aplicación móvil. Una vez que se
        elimine la cuenta, los datos serán irrecuperables.
      </p>

      <h3>VIGENCIA</h3>
      <p>
        La presente Política de Datos Personales entra en vigencia a partir del
        01 de marzo de 2023, cualquier cambio que se presente respecto de la
        presente política, se informara a través de los canales de información
        aportados por el titular de la información, y en la página web
        www.LUGUUio
      </p>
    </div>
  );
};

export default Terminos;

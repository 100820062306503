import { useState, useEffect } from "react";
import { Navbar, Container } from "react-bootstrap";
import logo from '../assets/img/logoLuguu.png';


export const NavBar = () => {

  
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  

  return (

      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" className="logo" width={100}/>
          </Navbar.Brand>
         
          <Navbar.Collapse id="basic-navbar-nav">
            
            
          </Navbar.Collapse>
        </Container>
      </Navbar>
  
  )
}

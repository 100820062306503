import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Terminos } from "./components/terminos";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <NavBar/>
        <Routes>
          <Route exact path="/" element={<div><Banner/><Contact/><Footer/></div>} />
          <Route exact path="/politicas" element={<Terminos />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logoLuguu.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { Route, Link, Routes } from "react-router-dom";
import Terminos from "./terminos";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={8} sm={6}>
            <br></br>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <br></br>
              <a href="https://www.linkedin.com/company/luguu/" target="_blank">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="https://www.facebook.com/LuguuDriverApp" target="_blank">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a href="https://www.instagram.com/luguuapp/" target="_blank">
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
            <div>
              <Link to="/politicas">Política de Tratamiento de datos</Link>
            </div>
          </Col>
        </Row>
      </Container>

      <Routes>
        <Route path="/politicas" target="_blank" element={<Terminos />} />
        <Route path="*" component={NotFound} />
      </Routes>
    </footer>
  );
};

function NotFound() {
  return <>Ha llegado a una página que no existe</>;
}
